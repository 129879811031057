import api from '../library/axios';
import RSA from '../library/rsa-encrypt';
import ls from '../library/localStorage';

export default {
  getList(page, search) {
    return api({
      method: 'get',
      url: '/api/backoffice/users',
      params: {
        page,
        search,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  getPIC() {
    return api({
      method: 'get',
      url: '/api/backoffice/users',
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  getRoles() {
    return api({
      method: 'get',
      url: '/api/backoffice/roles',
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  createUser({
    full_name,
    email,
    phone_number,
    password,
    role_id,
  }) {
    return api({
      method: 'post',
      url: '/api/backoffice/users',
      data: {
        full_name,
        email,
        phone_number,
        role_id,
        password: RSA.encrypt(password, 'base64'),
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  update(user_id, {
    full_name,
    email,
    phone_number,
    password,
    role_id,
  }) {
    return api({
      method: 'patch',
      url: `/api/backoffice/users/${user_id}`,
      data: {
        full_name,
        email,
        phone_number,
        password,
        role_id,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  delete(user_id) {
    return api({
      method: 'delete',
      url: `/api/backoffice/users/${user_id}`,
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
};
