import api from '../library/axios';

export default {
  dashboardAnalytic({
    workspace_id, date_type, start_date, end_date,
  }) {
    return api({
      method: 'get',
      url: `/api/analytics/dashboard/${workspace_id}`,
      params: {
        date_type,
        start_date,
        end_date,
      },
    });
  },
  workspaceStats({
    workspace_id,
    company_id,
  }) {
    return api({
      method: 'get',
      url: `/api/analytics/stats/${workspace_id}`,
      params: {
        company_id,
      },
    });
  },
};
