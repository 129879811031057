import { Message } from 'element-ui';

/* eslint-disable */
export default function (response, cb = function () {}) {
	return new Promise((resolve, reject) => {
		if (response.error) {
			let error_message = response.error.message;
			if (response.data?.error?.error_user_msg) {
				error_message = response.data?.error?.error_user_msg;
			}

			if (response.data?.error_response && response.data?.error_response[0] && response.data.error_response[0].error?.error_user_msg) {
				error_message = response.data.error_response[0].error?.error_user_msg;
			}
			Message({
				message: `Failed. ${error_message}`,
				type: 'error',
			});
			cb();
			reject();
			return;
		}
		resolve();
	})
}
